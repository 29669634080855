<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Work Permit</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Title *"
                    v-model="workPermit.title"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Title'"
                    @blur="$v.workPermit.title.$touch()"
                    :is-valid="
                      $v.workPermit.title.$dirty
                        ? !$v.workPermit.title.$error
                        : null
                    "
                  >
                    <!-- <template #description>
                      <CRow>
                        <CCol md="12">
                          <small
                            style="float: right"
                            class="form-text text-muted"
                          >{{faq.title.length}}</small>
                        </CCol>
                      </CRow>
                    </template> -->
                  </CInput>
                  <CInput
                    label="Doc No *"
                    v-model="workPermit.docNo"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Title'"
                    @blur="$v.workPermit.docNo.$touch()"
                    :is-valid="
                      $v.workPermit.docNo.$dirty
                        ? !$v.workPermit.docNo.$error
                        : null
                    "
                  />
                  <CInput
                    label="Revision Number *"
                    v-model="workPermit.divisionNumber"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Title'"
                    @blur="$v.workPermit.divisionNumber.$touch()"
                    :is-valid="
                      $v.workPermit.divisionNumber.$dirty
                        ? !$v.workPermit.divisionNumber.$error
                        : null
                    "
                  />
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                      >Need to Approval</label
                    >
                    <div class="col-sm-6">
                      <input
                        type="radio"
                        v-model="workPermit.approved"
                        value="true"
                        :disabled="mode.viewMode"
                      />
                      Yes
                      <input
                        type="radio"
                        v-model="workPermit.approved"
                        value="false"
                        :disabled="mode.viewMode"
                      />
                      No
                    </div>
                  </div>

                  <CInputFile
                    label="File "
                    horizontal
                    type="file"
                    accept="pdf/*"
                    :disabled="mode.viewMode"
                    @change="uploadImage"
                  />
                  <div>
                    {{ currentPage }} / {{ pageCount }}
                    <pdf
                      :src="imageUrl(workPermit.fileId)"
                      @num-pages="pageCount = $event"
                      @page-loaded="currentPage = $event"
                      style="display: inline-block; width: 50%"
                    ></pdf>
                    <!-- <pdf
            v-for="i in numPages"
            :key="i"
            :src="imageUrl(workPermit.fileId)"
            :page="i"
            style="display: inline-block; width: 15%"
        ></pdf> -->
                  </div>
                  <!--  :is-valid="$v.task.fileId.$dirty? !$v.task.fileId.$error  : null"
                  @blur="$v.task.fileId.$touch()"
                 invalid-feedback="Please upload the Image"-->
                  <!-- <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard v-if="workPermit.fileId && !$v.workPermit.fileId" style="margin-top: 20px">
                      
                        <CCardBody align="center">
                      <CButton color="danger"  style="float: right" @click="removeimage() "  :disabled="mode.viewMode">
                        <CIcon   name="cilXCircle" description="Delete"  ></CIcon>
                         </CButton>
                          <CImg
                            v-if="workPermit.fileId"
                            :src="imageUrl(workPermit.fileId)"
                            height="180px"
                            alt="Responsive pdf"
                           
                          > </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                   </CRow> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import pdf from "vue-pdf";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "WorkPermit",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      currentPage: 0,
      pageCount: 0,
      permitFormId: "",
      workPermit: {
        permitFormId: "",
        title: "",
        docNo: "",
        approved: "false",
        divisionNumber: "",
        fileId: "",
        groupId: this.$route.params.groupId,
      },
      //   categories:[],
      //   selected: [],
      //   roles:[],
      //   selectedRoles:[],
      //   show: true,
      horizontal: { label: "col-3", input: "col-9" },

      //   selectedOption: "some value",

      //   formCollapsed: true,
      //   checkboxNames: [
      //     "Checkboxes",
      //     "Inline Checkboxes",
      //     "Checkboxes - custom",
      //     "Inline Checkboxes - custom",
      //   ],
    };
  },

  validations: {
    workPermit: {
      title: {
        required,
      },
      docNo: {
        required,
      },
      divisionNumber: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.groupId = this.$route.params.groupId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.permitFormId = this.$route.params.permitFormId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.permitFormId = this.$route.params.permitFormId;
      this.getUser();
    }
  },
  components: { pdf },
  methods: {
    imageUrl(fileId) {
      console.log(fileId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },

    removeimage() {
      this.workPermit.fileId = "";
    },

    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      // fdata.append("userId","uid");

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          this.workPermit.fileId = data.data.fileId;
        });
    },
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Work Permits",
        params: { groupId: this.$route.params.groupId },
      });
    },
    saveSpeciality() {
      this.$v.workPermit.$touch();
      if (this.$v.workPermit.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.workPermit));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/workPermit/forms",
          this.workPermit
        ).then((post) => {
          this.$router.push({
            name: "Work Permits",
            params: { groupId: this.$route.params.groupId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/workPermit/forms/" +
          this.permitFormId
      )
        .then((response) => response.json())
        .then((data) => (this.workPermit = data));
    },
  },
  mounted() {},
};
</script>
